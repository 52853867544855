'use client';
import Link from 'next/link';
import './landing.css';
import { Button } from '@/components/ui/button';

export default function Layout({ params, children }: { params: { client: string }; children: React.ReactNode }) {
  return (
    <div className="bg-white absolute inset-0 white overflow-scroll text-black">
      <div className="w-full max-w-[1300px] mx-auto pt-[64px] px-5 h-full mb-20">
        <MenuBar />
        <div>
          <h1 className="text-[44px] font-bold max-w-[680px] pt-[130px] leading-[50px]">Classify accounts with your own custom verticals</h1>
          <p className="max-w-[586px] text-base pt-4 leading-6">
            Using{' '}
            <Link href={'https://www.perplexity.ai/search/what-are-embeddings-kgB0jFqPQ6aQCVKTROTDJg'} target="_blank" className="text-purpleLight font-bold">
              embeddings
            </Link>
            , your new AI agents work across all sources, show their work, and get better as you use them. Engineered for trust and scale.
          </p>
        </div>
        <div className="pt-[56px]">
          <span className="text-sm opacity-40">Task name</span>
          <h2 className="text-[28px] font-bold">Vertical classification</h2>
        </div>
        {children}
      </div>
    </div>
  );
}

function MenuBar() {
  return (
    <div className="w-full mx-auto flex justify-between items-center">
      <div className="flex items-center space-x-2">
        <div className="h-7 w-7 bg-black rounded-md"></div>
        <span className="text-2xl font-semibold">Momentum</span>
      </div>
      <div className="space-x-11">
        <Link href={'generatemomentum.ai'} className="font-semibold text-sm">
          Visit homepage
        </Link>
        <Button className="bg-purpleLight text-white hover:bg-purple/90 font-semibold">Talk to founders</Button>
      </div>
    </div>
  );
}
